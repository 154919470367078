.subscribe,
.contact,
.redirect {
  margin-top: 12vh;
  position: relative;
  width: 100%;
  height: 88vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}
.detalii,
.feedback {
  margin-top: 12vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.detalii {
  margin-top: 0;
}
.contact,
.redirect {
  flex-direction: column;
}
.contact {
  justify-content: flex-start;
}
.fixed {
  position: fixed;
  z-index: 10;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 20%;
}

@media screen and (max-width: 480px) {
  .main,
  .subscribe,
  .contact,
  .redirect {
    margin-top: 10vh;
    height: 90vh;
  }
  .feedback {
    margin-top: 10vh;
  }
  .detalii {
    padding: 1rem;
    padding-bottom: 0;
  }
  .feedback,
  .subscribe {
    align-items: flex-start;
  }
}
