.background {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
}

.background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


