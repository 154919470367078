nav {
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  max-width: 100vw;
  height: 12vh;
  z-index: 2;
  box-shadow: rgba(109, 109, 109, 0.2) 0 4px 15px -4px;
  background-color: white;
}
nav ul {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  color: black;
  padding-right: 15%;
}
nav ul a {
  cursor: pointer;
  text-decoration: none;
  color: black;
}
.nav-active {
  transform: translate(0%, 0%);
}

nav .logo {
  height: 11vh;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 15%;
}

nav .logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.solo-link {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}

.burger {
  display: none;
  z-index: 4;
}
.burger div {
  border-radius: 4px;
  width: 2rem;
  height: 3px;
  background-color: rgb(36, 36, 36);
  transition: all 0.3s ease;
}
.link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hover:hover {
  color: rgb(227, 117, 0);
}

@media screen and (max-width: 480px) {
  nav {
    height: 10vh;
  }
  nav .logo {
    height: 9vh;
    margin-left: 5%;
    padding: 0;
  }
  nav ul {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    top: 10vh;
    right: 0;
    height: 90vh;
    width: 60%;
    transform: translate(100%, 0%);
    font-size: 1.2rem;
    background-color: rgb(255, 255, 255);
    transition: transform 0.3s ease-in;
    margin-right: 0;
    padding: 0;
  }
  nav ul a {
    font-size: 1rem;
  }
  .burger {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    margin-right: 5%;
  }
  .burger img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .background-blur {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
    top: 10vh;
  }
}
